<template>
  <div class="group">
    <el-header class="group-header">
      <div class="home-title">
        <img src="../../assets/img/logo.png" alt="" width="35px" height="35px">
        <span>十户联动</span>
      </div>
    </el-header>
    <div class="group-wrap">
      <div class="camera-list">
        <div class="list-title">十户联动单位列表</div>
        <div class="list-box">
          <div class="list-wrapper" v-for="(item,i) in cameraList" :key="i" @click="changeCamera(item.cameraCode)">
            <div class="wrapper-row1">
              <el-tooltip :content="item.unitName" placement="top" effect="dark">
                <div>{{i+1}}.单位:{{item.unitName}}</div>
              </el-tooltip>
              <el-tooltip :content="item.legalName" placement="top" effect="dark">
                <div>负责人:{{item.legalName}}</div>
              </el-tooltip>
            </div>
            <div class="wrapper-row2">
              <el-tooltip :content="item.legalPhone" placement="top" effect="dark">
                <div>联系电话:{{item.legalPhone}}</div>
              </el-tooltip>
            </div>
            <div class="wrapper-row3"></div>
          </div>
        </div>
      </div>
      <div class="camera-monitor">
        <div class="camera-title">单位摄像头实时预览</div>
        <div class="camera-box">
          <!-- <EZUIKit class="camera" :width="1000" :height="600" :videoUrl="streamList" ref="monitor" :splitBasis="9" /> -->
          <div class="camera" ref="cameraBox">
            <HikVision ref="hik" :width="width" :height="height" :pointCode="cameraCode" :layout="'2x2'" v-if="show" :info="cameraInfo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HikVision from '../../components/HikVision.vue'
export default {
  components: {
    HikVision
  },
  data () {
    return {
      cameraList: [],
      cameraCode: '',
      cameraInfo: {
        appKey: '22957985',
        appSecret: 'bAGDoSJOukv16FPWj8QA',
        host: '202.109.166.101:1443'
      },
      height: 0,
      width: 0,
      unitId: 0,
      currentPage: 1,
      total: 0,
      show: false
    }
  },
  created () {
    this.lookVideo()
  },
  methods: {
    // 查看视频
    async lookVideo () {
      const obj = {
        groupId: 21,
        typeListIds: 14
      }
      const { data: result } = await this.$axios.get('/equipment/findEquipmentByUnitIdWithTypeId', {
        params: obj
      })
      if (result.code !== 200) return this.$message.error(result.msg)

      console.log(this.cameraList)
      this.cameraList = result.data
      let array = []
      for (let i = 0; i < result.data.length; i++) {
        if (result.data[i].playUrl.length > 0) {
          array = array.concat(result.data[i].playUrl)
          array = array.filter(item => item)
        }
      }

      console.log(array)
      this.cameraCode = array.join(',')
      this.$nextTick(() => {
        this.width = this.$refs.cameraBox.offsetWidth
        this.height = this.$refs.cameraBox.offsetHeight
        this.show = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.group {
  .group-header {
    color: #39b8e6;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 76px !important;
    font-size: 18px;
    padding: 0 1%;
    background: url("../../assets/img/bg-header.png") no-repeat center center;
    background-size: 100% 100%;
    .home-title {
      height: 100%;
      width: 20%;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: 100% 100%;
      color: #fff;
      span {
        letter-spacing: 2px;
      }
    }
  }
  .group-wrap {
    height: calc(100vh - 76px);
    width: 100%;
    display: flex;
    padding: 0 2.5% 2% 2.5%;
    box-sizing: border-box;
    background: url("../../assets/img/bg-body.png") no-repeat center center;
    background-size: 100% 100%;
    .camera-list {
      flex: 3;
      background-color: transparent;
      overflow: hidden;
      margin-right: 10px;
      .list-title {
        color: #fff;
        line-height: 40px;
        height: 40px;
        background-image: url("../../assets/img/group-header.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .list-box {
        height: calc(100% - 40px);
        overflow-y: scroll;
        background-image: url("../../assets/img/group-body.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .list-wrapper {
          padding: 5px 15px;
          text-align: left;
          box-sizing: border-box;
          .wrapper-row1 {
            width: 100%;
            color: #fff;
            font-size: 13px;
            display: flex;
            div {
              flex: 1;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
          .wrapper-row2 {
            width: 100%;
            margin-top: 10px;
            color: #409eff;
            font-size: 13px;
            display: flex;
            align-items: center;
            div {
              width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
          .wrapper-row3 {
            width: 100%;
            height: 1px;
            margin-top: 10px;
            background-image: linear-gradient(
              to right,
              #ccc 0%,
              #ccc 50%,
              transparent 50%
            );
            background-size: 8px 1px;
            background-repeat: repeat-x;
          }
        }
      }
    }
    .camera-monitor {
      flex: 8;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      .camera-title {
        width: 100%;
        color: #fff;
        line-height: 40px;
        height: 40px;
        background-image: url("../../assets/img/video-header.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .camera-box {
        width: 100%;
        height: calc(100% - 40px);
        background-image: url("../../assets/img/video-body.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        .camera {
          width: 100%;
          height: 100%;
        }
      }
    }
    .danger {
      color: #f56c6c;
    }
    .success {
      color: #67c23a;
    }
  }
}
</style>
